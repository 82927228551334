import React, { Component } from 'react';
import $ from 'jquery';
import jQuery from 'jquery';
import axios from 'axios';
import swal from 'sweetalert';

class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      btnLoading: false,
      showMessage: false
    }
  }
  componentDidMount() {
    this.validation();
  }
  sendLink(event) {
    event.preventDefault();
    var email = this.refs.emailLink.value;
    var formValues = {
      username: email,
      "emailSubject": "Email Verification",
      "emailContent": "As part of our registration process, we screen every new profile to ensure its credibility by validating email provided by user. While screening the profile, we verify that details put in by user are correct and genuine.",
    }
    if ($('#resetPass').valid()) {
    this.setState({ ...this.state, btnLoading: true });

      axios.patch('/api/auth/patch/setsendemailotpusingEmail/' + email, formValues)
        .then((response) => {
          console.log(response);
          if(response.data.message=='NOT_REGISTER') {
            swal("This Email ID is not registered");
            $('.fullpageloader').hide();
            this.setState({ ...this.state, btnLoading: false })
            return
          }
          this.setState({ ...this.state, btnLoading: false },()=>{
            document.getElementById("logInBtn").innerHTML = 'Reset Password';
          });
          localStorage.setItem('previousUrl', 'forgotpassword');
          swal("Verification Code is sent to your registered Email ID.");
          this.props.history.push('/confirm-otp/' + response.data.ID);

        })
        .catch((error) => {
          this.setState({ ...this.state, btnLoading: false },()=>{
          document.getElementById("logInBtn").innerHTML = 'Reset Password';
          })
          swal("This Email ID is not registered");
          $('.fullpageloader').hide();
        })
    }
  }
  Closepagealert(event) {
    event.preventDefault();
    $(".toast-error").html('');
    $(".toast-success").html('');
    $(".toast-info").html('');
    $(".toast-warning").html('');
    $(".toast-error").removeClass('toast');
    $(".toast-success").removeClass('toast');
    $(".toast-info").removeClass('toast');
    $(".toast-warning").removeClass('toast');
  }

  validation() {
    $.validator.addMethod("regxemail", function (value, element, regexpr) {
      return regexpr.test(value);
    }, "Please enter a valid email address.");


    jQuery.validator.setDefaults({
      debug: true,
      success: "valid"
    });

    $("#resetPass").validate({
      rules: {
        emailLink: {
          required: true,
          regxemail: /^\b[A-Z0-9._%-]+@[A-Z0-9.-]+\.[A-Z]{2,4}\b$/i
        },
      },
      errorPlacement: function (error, element) {

        if (element.attr("name") === "emailLink") {
          error.insertAfter("#emailLink");
        }
      }
    });
  }

  render() {
    return (
      <div style={{ 'height': window.innerHeight + 'px', 'width': window.innerWidth + 'px' }} className="col-lg-12 col-md-12 col-sm-12 col-xs-12 LoginWrapper">
        <div className="col-12 mt-5">
          <div className="col-12 col-lg-2 rounded px-4 py-3 logoWrapper">
            <img src="/images/Logo.png" className="logo" alt="logo" />
          </div>
          <div className="col-12 col-md-8 offset-md-2 col-lg-5 offset-lg-5 mt-5">
            <img src="/images/periscopeLogo.png" className="periscopeLogo" alt="Periscope" />
          </div>
          <div className="col-12 col-md-8 offset-md-2 col-lg-5 offset-lg-5 mt-5 text-center">
            <div className="col-12 col-lg-10 offset-lg-1 loginCase">
              <div className="col-12">
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 innloginwrap">
                  <p className="col-lg-12 col-md-12 col-sm-12 col-xs-12 mt25">Please enter your registered email address below to receive a Verification Code.</p>
                </div>
                <form id="resetPass">
                  <div className="form-group textAlignLeft col-lg-12 col-md-12 col-sm-12 col-xs-12 NOpadding mt25">
                    <label>Email ID</label><label className="astricsign">*</label>
                    <input className="form-control col-lg-12 col-md-12 col-sm-12  col-xs-12" placeholder="Email ID" ref="emailLink" name="emailLink" type="text" />
                    <div id="emailLink"></div>
                  </div>

                  {
                    this.state.btnLoading
                      ?
                      <div className="col-lg-3 col-lg-offset-4 col-md-10 col-md-offset-1 col-sm-12 col-xs-12 NOpaddingRight ">
                        <div align="center" className="cssload-fond">
                          <div  className="cssload-container-general">
                            <div className="cssload-internal"><div className="cssload-ballcolor cssload-ball_1"> </div></div>
                            <div className="cssload-internal"><div className="cssload-ballcolor cssload-ball_2"> </div></div>
                            <div className="cssload-internal"><div className="cssload-ballcolor cssload-ball_3"> </div></div>
                            <div className="cssload-internal"><div className="cssload-ballcolor cssload-ball_4"> </div></div>
                          </div>
                        </div>
                      </div>
                      :
                      <div className="mt-5 col-10 offset-1 ">
                        <button id="logInBtn" onClick={this.sendLink.bind(this)} className="col-12 btn loginBtn"  >SEND VERIFICATION CODE</button>
                      </div>
                  }
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default ForgotPassword;