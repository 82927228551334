import React,{Component}                              from 'react';
import { render }                                     from 'react-dom';
import $                                              from "jquery";
import axios from 'axios';
import { BrowserRouter, Route, Switch,Link,location } from 'react-router-dom';
import { withRouter } from 'react-router-dom';

import Rightsidebar from '../rightSidebar/Rightsidebar.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.min.css'; 
import './Header.css';


class Header2 extends Component{

  constructor(props) {
    super(props);
    this.state = {
      loggedIn: false,
      showNotification: false,
      inAppNotifications:[],
      notifCount:0
    
    }
  }
  componentDidMount(){
    $("html,body").scrollTop(0);

    //======= User Details ==========
    var userDetails = JSON.parse(localStorage.getItem("userDetails")) ;
    const Token     = userDetails.token;
    
    console.log("header userDetails = ", userDetails);

      if(Token){
         this.setState({
            user_ID       : userDetails.user_id,
            email         : userDetails.email,
            profileImage  : userDetails.image,
            firstname     : userDetails.firstname,
            lastname      : userDetails.lastname,
            fullname      : userDetails.firstName+' '+userDetails.lastName,
            companyID     : userDetails.companyID,
            roles         : userDetails.roles,
            isAdmin       : (userDetails.roles.includes('Admin') || userDetails.roles.length===0) ? true : false ,
         },()=>{
             console.log("this.state.fullname = ",this.state.fullname);
         });

      }else{
         this.props.history.push("/login");
      }
   }

   componentWillReceiveProps(nextProps){
      // const user_ID = localStorage.getItem("user_ID");
      // axios.get('/api/notifications/get/list/Unread/'+user_ID)
      //    .then(notifications => {
      //    this.setState({ inAppNotifications: notifications.data,notifCount: notifications.data.length })
      //    })
      //    .catch(error => {
      //    })
   }
   componentWillUnmount(){
      $("script[src='/js/adminLte.js']").remove();
      $("link[href='/css/dashboard.css']").remove();
   }

   openNav(){
      document.getElementById("mySidenav").style.width = "250px";
   }

   closeNav() {
      document.getElementById("mySidenav").style.width = "0";
   }

   toggleLeftNav(event) {
      event.preventDefault()
      console.log("=== toggleLeftNav ===");
      $('#sidebar').toggleClass('active')
      $('#headerid').toggleClass('headereffect');
      $('#dashbordid').toggleClass('dashboardeffect')
      var sideBar = $("#sidebar").hasClass("active")
      localStorage.setItem('sideBar', sideBar);
   }

   toggleNav(event) {
      event.preventDefault();

      console.log("=== toggleNav ===")

      var currentWidth = document.getElementById("mySidenav").style.width;
      if (currentWidth == "230px") {
         document.getElementById("mySidenav").style.width = "0";
      } else {
         document.getElementById("mySidenav").style.width = "230px";
      }
   }

   logout() {
      $("script[src='/js/adminLte.js']").remove();
      $("link[href='/css/dashboard.css']").remove();
      var del = localStorage.removeItem("userDetails");
      if (del !== null) {
         this.setState({
            loggedIn: false
         }) 
      }
   }
   LogoutSectionHover(event) {
      $(".colorboxbefore").toggleClass("colorbox");
      $('.showme').toggle();
   }

   bellNotification(event) {
      $('.bellnotification').toggle();
      const user_ID = localStorage.getItem("user_ID");
      // axios.get('/api/notifications/get/list/Unread/'+user_ID)
      //    .then(notifications => {
      //    // console.log('notifications: ',notifications)
      //       this.setState({ inAppNotifications: notifications.data,notifCount: notifications.data.length })
      //    })
      //    .catch(error => {
      //    })
   }

   viewAll(id,event){
      $('.bellnotification').toggle();
      axios.put('/api/notifications/put/'+id)
      .then((res)=>{
         this.props.history.push("/ViewAllNotification");
      })
      .catch((err)=>{
         console.log(err)
      })
   }
   showDropdown(event){
      $("#showhide").addClass("showhim");
      $("#showhidearrow").addClass("showhim");
   }
   hideDropdown(event){
      $("#showhide").removeClass("showhim");
      $("#showhidearrow").removeClass("showhim");
   }

   render(){
      return(
         <div className="">
            <header className="main-header newMain-header">
               <nav className="navbar navbar-static-top py-0" id="navbar1" role="navigation">
                  <div className="col-12 mt-0">
                     <div className="row">
                        <div className={this.state.isAdmin ? "col-6 offset-4 col-md-4 offset-md-7 col pl-0 pr-5 text-center" : "col-6 offset-4 col-md-4 offset-md-8 col pl-0 pr-5 text-center" }>
                           <div className="col-12 py-3 px-0" onClick={this.LogoutSectionHover.bind(this)}>
                              <img src={this.state.profileImage ? this.state.profileImage : "/images/person.png"} className="userIcon"/>
                              <label className="mailtext">&nbsp;&nbsp;&nbsp;{this.state.fullname ? this.state.fullname : ""}</label>
                           </div>
                        </div>

                        {
                           this.state.isAdmin
                           ?
                              <div className="col-1 offset-1 col-md-1 offset-md-0 px-0 textAlignCenter onHoverEffect hover" onClick={this.toggleNav.bind(this)} >
                                 <i className="fa fa-cogs headicon"></i>
                              </div>
                           :
                              null
                        }

                        <div className="col-8 offset-3 col-md-3 offset-md-8 bg-white border showme px-0 mt-1">
                           <div className="col-12 nopadding">                                 
                              <div className="col-12 headerImageContainer padd0">
                                 <p className="pull-right fntC1" style={{"cursor":"pointer"}} title="Close"  onClick={this.LogoutSectionHover.bind(this)}>X</p><br/>
                                 <div className=" marLeft "  style={{"backgroundImage":`url(`+ (this.state.profileImage ? this.state.profileImage : "/images/person.png")+`)`, "height": "40%", "backgroundSize":"41% 100%","backgroundRepeat": "no-repeat"}}></div>
                                 <div className="col-12 marTop pull-right  padd0 ">
                                    <h5 className="nomargin dropmailtext">
                                       {this.state.fullname ? this.state.fullname : ""}
                                    </h5>
                                    <h6 className=" dropmailtext"> {this.state.email ? this.state.email : ""}</h6>
                                 </div>
                              </div>
                           </div>
                           <div className="btnDiv col-lg-12 col-md-12 col-sm-12 col-xs-12">
                              <span className="">
                                 <a className="profileTitle btnpadd" href={"/profile/"+this.state.user_ID}>
                                    <button type="button" className="btn resetBtn col-5 py-2">Profile</button>
                                 </a>
                              </span> &nbsp;
                              <span className="">
                                 <a className="profileTitle btnpadd" href="/login">
                                    <button type="button" className="btn resetBtn col-5 offset-1 py-2" onClick={this.logout.bind(this)}>Sign Out</button>
                                 </a>
                              </span>
                           </div>
                        </div>
                     </div>
                  </div>
               </nav>
               <div id="mySidenav" className="sidenav">
                  <Rightsidebar />
               </div>
           </header>
         </div>
      );
   }
}
export default withRouter(Header2);
/*
href={"/Profile/"+ Meteor.userId()}*/