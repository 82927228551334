import { useState, useEffect } from "react";
import axios from 'axios';
import moment from 'moment';
import swal from 'sweetalert';
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

export default () => {

    const handleOrderClick = (data) => {
        let a = document.createElement('a');
        a.target = '_blank';
        a.href = `/order-details/${data.orderNum}`;
        a.click();
    }

    return (
        <div className="formWrapper">
            <Table handleOrderClick={handleOrderClick} />
        </div>
    );
};



const OrderNoBtn = (props) => {
    const handleBtnClick = () => {
        props.handleOrderClick(props.data);
    }
    return <button type="button" className="btn-link" onClick={handleBtnClick}>{props.title}</button>
}


const Table = (props) => {

    const [dataList, setDataList] = useState([])
    const [loader, setLoader] = useState(false)
    const [pageLimit, setPageLimit] = useState(10)
    const [filter, setFilter] = useState({})

    const columns = [
        {
            key: 'orderNum',
            name: 'Order No',
            colSize: "w100", type: "string",
            children: OrderNoBtn,
            isSortable: true
        },
        {
            key: 'orderReceivedDate',
            name: 'Received date',
            colSize: "w150", type: "date",
            isSortable: true

        },
        {
            key: 'marketingRep',
            name: 'MSS',
            colSize: "w150", type: "string",
            isSortable: true

        },
        {
            key: 'patientName',
            name: 'Patient Name',
            colSize: "w150", type: "string",
            isSortable: true

        }, {
            key: 'btPatientId',
            name: 'BT-Id',
            colSize: "w100", type: "string",
            isSortable: true

        }, {
            key: 'status',
            name: 'Status',
            colSize: "w150", type: "string",
            isSortable: true

        }, {
            key: 'assignedTo',
            name: 'Assigned To',
            colSize: "w150", type: "string",
            isSortable: true

        }, {
            key: 'referringFacility',
            name: 'Referring Facility',
            colSize: "w200", type: "string",
            isSortable: true

        },
        {
            key: 'orderingDoctor',
            name: 'Ordering Doctor',
            colSize: "w200", type: "string",
            isSortable: true

        }, {
            key: 'hcpcCodes',
            name: 'HCPCS',
            colSize: "w100", type: "array",
            isSortable: true

        },
        {
            key: 'facilityState',
            name: 'Facility State',
            colSize: "w200", type: "string",
            isSortable: true

        }
    ]

    useEffect(() => {
        setLoader(true)
        axios
            .get('/api/orders/get/list')
            .then((response) => {
                setLoader(false)
                setDataList(response.data.orders.sort((a, b) => new Date(b.orderReceivedDate) - new Date(a.orderReceivedDate)))
            })
            .catch(err => {
                setLoader(false)
                swal(err)
            })
    }, [])

    const [page, setPage] = useState(1);
    let pages = 2
    let dataRows1 = []
    const getPaginatedEntries = () => {
        dataRows1 = dataList.filter(entry => {
            const orderReceivedDate = moment(entry['orderReceivedDate']).format('YYYY-MM-DD')
            let isValidRow = true
            if (filter['fromDate'] !== undefined || filter['toDate'] !== undefined) {
                const fromDate = filter['fromDate'] !== undefined ? moment(filter['fromDate']).format('YYYY-MM-DD') : orderReceivedDate
                const toDate = filter['toDate'] !== undefined ? moment(filter['toDate']).format('YYYY-MM-DD') : moment(Date.now()).format('YYYY-MM-DD')
                isValidRow = orderReceivedDate >= fromDate && orderReceivedDate <= toDate
            }

            if (Object.keys(filter).length === -1) return isValidRow

            return isValidRow && Object
                .entries(filter)
                .filter(data =>
                    data[0] !== 'fromDate' &&
                    data[0] !== "toDate" &&
                    data[0] !== 'search' &&
                    data[1] !== "" &&
                    data[0] !== 'sort')
                .every(data => data[1]?.includes(entry[data[0]]))
                && (filter.search !== undefined ?
                    entry['orderNum']?.toString().includes(filter?.search.toLowerCase()) ||
                    entry['btPatientId']?.toString().includes(filter?.search) ||
                    entry['marketingRep']?.toLowerCase().includes(filter?.search.toLowerCase()) ||
                    entry['patientName']?.toLowerCase().includes(filter?.search.toLowerCase()) ||
                    entry['assignedTo']?.includes(filter?.search) ||
                    entry['referringFacility']?.toLowerCase().includes(filter?.search.toLowerCase()) ||
                    entry['hcpcCodes']?.toString().includes(filter?.search) ||
                    entry['wipStatus']?.toLowerCase().includes(filter?.search.toLowerCase()) ||
                    entry['status']?.toLowerCase().includes(filter?.search.toLowerCase()) ||
                    entry['hcpcCodes']?.join(',').toLowerCase().includes(filter?.search.toLowerCase())
                    : true)

        })

        pages = dataRows1.length > 0 ? dataRows1.length - 1 : 1
        return dataRows1.sort((a, b) => {
            const v1 = a[filter?.sort?.key]
            const v2 = b[filter?.sort?.key]
            if (isNaN(v1) && isNaN(v2)) return filter?.sort?.type === 1 ? v1?.localeCompare(v2) : v2?.localeCompare(v1)
            return filter?.sort?.type === 0 ? v1 - v2 : v2 - v1
        }).slice((page - 1) * pageLimit, page * pageLimit);
    };

    const handlePageLimit = (e) => {
        setPageLimit(parseInt(e.target.value))
    }

    const dataRows = getPaginatedEntries()

    const handleSort = (event) => {
        setFilter({ ...filter, sort: { key: event.target.id, type: filter?.sort?.type === 1 ? 0 : 1 } })
    }

    return <div className="pageContent px-5  pt-1">
        <div className="row justify">
            <div className="col-12 pt-4 pageHeader">
                Order List
            </div>
            <hr className="hr-head mt-0 mb-5 container-fluid row" />
            <FilterComponent
                handlePageLimit={handlePageLimit}
                pageLimit={pageLimit}
                filter={filter}
                handleFilter={setFilter}
                setPage={setPage}
                dataList={dataRows1}
            />
            <div className="pt-3 pl-0 col-12 overflow-auto scroll-auto">
                <table className="table table-hover table-bordered table-striped table-height tableFixHead">
                    <thead className="tempTableHeader">
                        <tr>
                            <th scope="col">#</th>
                            {columns.map((column) => (
                                <th
                                    scope="col"
                                    className={`${column.colSize} ${filter?.sort?.key == column.key && 'text-primary'}  text-uppercase `}>
                                    {column.name}
                                    {column.isSortable && <i
                                        id={column.key}
                                        value={column.key}
                                        className={`fa fa-fw  ${column.key != filter?.sort?.key && 'fa-sort'} ${column.key == filter?.sort?.key && (filter?.sort?.type == 1 ? 'fa-arrow-up text-warning' : 'fa-arrow-down text-warning')}`}
                                        onClick={handleSort}></i>}

                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {dataRows.length > 0 ? (
                            dataRows.map((data, index) => (
                                <tr>
                                    <td className="col-md-4 col-sm-4, col-lg-4 text-center align-middle" >{page === 1 ? index + 1 : `${(page == 1 ? 1 : page - 1) * pageLimit + (index + 1)}`}</td>
                                    {columns.map((column, index) => (
                                        <td key={index} className={`col-md-4 col-sm-4, col-lg-4 ${column.key == 'status' ? 'text-center' : 'text-left'} align-middle`} >
                                            {
                                                column.children ? <column.children handleOrderClick={props.handleOrderClick} data={data} title={data[column.key] ?? ""} /> : column.type === "date"
                                                    ?
                                                    moment(data[column.key]).format("MM/DD/yyyy")
                                                    :
                                                    (
                                                        column.key == 'status' && <div
                                                            className={`badge-pill p-1
                                                        ${data[column.key] == "RECEIVED" && 'badge-primary'} 
                                                        ${data[column.key] == "SHIPPED" && 'badge-success'}
                                                        ${data[column.key] == "IN PROGRESS" && 'badge-info'}
                                                        ${data[column.key] == "PENDING" && 'badge-warning'}
                                                        ${data[column.key] == "CANCELLED" && 'badge-danger'}
                                                        ${data[column.key] == "FORWARDED" && 'badge-secondary'}
                                                        
                                                        `}>{data[column.key]}</div> ||
                                                        column.type == 'array' && (filter.search !== undefined && filter.search !== "" && data[column.key]?.join(',')?.toLowerCase()?.includes(filter?.search?.toLowerCase()) ? <span style={{ background: "yellow" }}>{data[column.key].join(',')}</span> : data[column.key].join(',')) ||
                                                        isNaN(data[column.key]) && filter.search !== undefined && filter.search !== "" && data[column.key]?.toLowerCase().includes(filter?.search?.toLowerCase()) && <span style={{ background: "yellow" }}>{data[column.key]}</span> ||
                                                        !isNaN(data[column.key]) && filter.search !== undefined && filter.search !== "" && data[column.key]?.toString().includes(filter?.search) && <span style={{ background: "yellow" }}>{data[column.key]}</span> ||
                                                        data[column.key]
                                                    )}
                                        </td>
                                    ))
                                    }
                                </tr>
                            ))
                        ) :
                            (
                                <center className="table-empty container align-middle">

                                    {loader ? <div className="loadingImg">
                                        <img src="/images/loading.gif" />
                                    </div> : <h3>No Data Available.</h3>}
                                </center>
                            )}
                    </tbody>
                </table>
            </div>
            <TablePagination
                currentPage={page}
                pages={pages}
                setPage={setPage}
                pageLimit={pageLimit}
                handlePageLimit={handlePageLimit}
            />
        </div>
    </div>
}

const FilterComponent = ({ filter, handleFilter, setPage, pageLimit, handlePageLimit, dataList }) => {
    const handleFilterChange = (event) => {
        handleFilter({ ...filter, [event.target.name]: event.target.value })
        setPage(1)
    }

    const resetFilter = () => { handleFilter({}) }

    const MSSList = [...new Set(dataList.map(data => data.marketingRep))].sort((a, b) => a?.localeCompare(b))
    const OrderingDoctorList = [...new Set(dataList.map(data => data.orderingDoctor))].sort((a, b) => a?.localeCompare(b))
    const AssignedToList = [...new Set(dataList.map(data => data.assignedTo))].sort((a, b) => a?.localeCompare(b))
    const StatusList = [...new Set(dataList.map(data => data.status))].sort((a, b) => a?.localeCompare(b))
    const FacilityStateList = [...new Set(dataList.map(data => data.facilityState))].sort((a, b) => a?.localeCompare(b))
    const ReferringFacilityList = [...new Set(dataList.map(data => data.referringFacility))].sort((a, b) => a?.localeCompare(b))

    const filterList = [
        {

            name: 'From Date',
            dataList: [],
            key: 'fromDate',
            type: 'date',

        },
        {

            name: 'To Date',
            dataList: [],
            key: 'toDate',
            type: 'date',

        }
        , {

            name: 'MSS',
            dataList: MSSList,
            key: 'marketingRep'

        },
        {
            name: 'Ordering Doctor',
            dataList: OrderingDoctorList,
            key: 'orderingDoctor'


        }, {
            name: 'Assigned To',
            dataList: AssignedToList,
            key: 'assignedTo'


        }, {
            name: 'Status',
            dataList: StatusList,
            key: 'status'


        },
        {
            name: 'Referring Facility',
            dataList: ReferringFacilityList,
            key: 'referringFacility'
        },
        {
            name: 'Referring Facility State',
            dataList: FacilityStateList,
            key: 'facilityState'
        },
    ]

    return <>
        <button className="btn btn-primary" data-toggle="collapse" data-target="#demo">Show/Hide Filter</button>
        <button className="ml-lg-5 ml-2 btn btn-primary " onClick={resetFilter} disabled={Object.keys(filter).length === 0}>Reset All Filters</button>
        <div id="demo" className="collapse">
            <div class="col-12  px-lg-3 px-1">
                <div className="row mt-3">
                    {filterList.map(filterItem => (
                        <div className="col-lg-3 col-xs-6  text-left ">
                            <label className=" labelform h5 mt-4  text-left"><b>{filterItem.name}</b></label>
                            {filterItem.type === 'date' ? <DatePicker
                                className="NOpadding-right zero form-control fa fa-search"
                                onChange={(date) => handleFilterChange({
                                    target: {
                                        name: filterItem.key,
                                        value: date
                                    }
                                })}
                                placeholderText="MM-DD-YYYY"
                                selected={filter[filterItem.key] ?? ""}
                            />
                                : <select
                                    onChange={handleFilterChange}
                                    value={filter[filterItem.key] ?? ''}
                                    name={filterItem.key}
                                    className="col-12  noPadding form-control">
                                    <option value={''} >-- Select --</option>
                                    {filterItem.dataList.map(data => <option name="marketingRep" value={data}>{data}</option>)}
                                </select>}
                        </div>
                    )
                    )}
                </div>
            </div>
        </div>
        <div class="col-12 px-0 mt-5 row">
            <div className="col-md-2 col-lg-2 col-xs-4 text-left">
                <label className=" labelform h5  text-left"><b>Rows / Page</b></label>
                <select onChange={handlePageLimit} value={pageLimit} id="limitRange" name="limitRange" className="col-lg-12 col-md-12 col-sm-6 col-xs-12  noPadding form-control">
                    <option value="Not Selected" disabled>Select Row Limit</option>
                    <option value={10}>10</option>
                    <option value={25}>25</option>
                    <option value={50}>50</option>
                    <option value={100}>100</option>
                    <option value={500}>500</option>
                    <option value={1000}>1000</option>
                    <option value={2000}>2000</option>
                </select>
            </div>
            <div className="col-lg-4 col-xs-8 pr-0 offset-lg-6 offset-0   text-left">
                <label className="h5 labelform text-left"><b>Search</b></label>
                <input
                    type="text"
                    placeholder={`Search By ${filter['search'] ?? ''}`}
                    onChange={handleFilterChange}
                    className="NOpadding-right zzero form-control fa fa-search"
                    id="tableSearch"
                    name="search"
                    value={filter['search'] ?? ''} />                
            </div>
        </div>

    </>
}




const TablePagination = ({ currentPage, pages, setPage, pageLimit, handlePageLimit }) => {
    const numPages = Math.ceil(pages / pageLimit);

    const renderPageBlocks = () => {
        return (
            <>
                {currentPage !== 1 && <li className="page-item">
                    <a className="page-link" href="#" onClick={goToPrevPage}>
                        {currentPage - 1}
                    </a>
                </li>}
                <li className="page-item active" disabled>
                    <a className="page-link" href="#">
                        {currentPage}
                    </a>
                </li>
                {currentPage < numPages && <li className="page-item">
                    <a className="page-link" href="#" onClick={goToNextPage}>
                        {currentPage + 1}
                    </a>
                </li>}
            </>
        );
    };


    const goToPrevPage = () => {
        if (currentPage > 1) {
            setPage(currentPage - 1);
        }
    };

    const goToNextPage = () => {
        if (currentPage < numPages) {
            setPage(currentPage + 1);
        }
    };

    const renderPrevPageBlocks = () => {
        return (
            <li className="page-item">
                <a className="page-link" href="#" onClick={() => setPage(1)}>
                    &#171;
                </a>
            </li>
        );
    };

    const renderNextPageBlocks = () => {
        return (
            <li className="page-item">
                <a className="page-link" href="#" onClick={() => setPage(numPages)}>
                    &raquo;
                </a>
            </li>
        );
    };




    const renderTotals = () => {

        return <li className="mx-2 col-8">
            <h6>{`Showing ${currentPage} of ${numPages}`} Pages</h6>
        </li>
    }

    return (
        <nav aria-label="Page navigation" className="text-center align-middle mb-5">
            <ul className="pagination col-12 d-flex justify-content-center flex-direction-row">
                {renderTotals()}
                {renderPrevPageBlocks()}
                {renderPageBlocks()}
                {renderNextPageBlocks()}
            </ul>
        </nav>
    );
};