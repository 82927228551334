import React, { Component }                       from 'react';
// import { connect }                                from 'react-redux';
// import { withRouter }                             from 'react-router-dom';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.min.css';


// Section: 1 - SystemSecurity ******************************************************
import Login                  from  './strive/systemSecurity/Login.js';
import ConfirmOtp             from  './strive/systemSecurity/ConfirmOtp.js';
import ForgotPassword         from  './strive/systemSecurity/ForgotPassword.js';
import ResetPassword          from  './strive/systemSecurity/ResetPassword.js';
import ResetPasswordInternal  from  './strive/systemSecurity/ResetPwd.js';
import Header                 from  './strive/common/header/Header.js'; 
import Footer                 from  './strive/common/footer/Footer.js';
import Leftsidebar            from  './strive/common/leftSidebar/Leftsidebar.js';

import CoreLayout             from  './strive/CoreLayout/CoreLayout1.js';
import Dashboard              from  './strive/dashboard/Dashboard.js'
// import UMListOfUsers       from  './strive/userManagement/UM/UMListOfUsers.js';
// import OrderList           from  './strive/orders/orderList.js';
import DeletedUsers           from  './strive/userManagement/UM/DeletedUsers.js';
import EditUserProfile        from  './strive/userManagement/UM/EditUserProfile.js';
import UserProfile            from  './strive/userManagement/UM/UserProfile.js';
import UMRolesList            from  './strive/userManagement/Roles/Roles.js';
import UMRolesEntity          from  './strive/userManagement/Roles/RolesEntity.js';
import UMListOfUsers          from  './strive/userManagement/UM/UMListOfUsers.js';
import CreateUser             from  './strive/userManagement/UM/CreateUser.js';

import ViewAllNotification    from  './strive/common/header/ViewAllNotifications.js';
import ViewTemplates          from  './strive/NotificationManagement/ViewTemplates.jsx';
import CreateTemplate         from  './strive/NotificationManagement/CreateTemplate.jsx';
import EditTemplate           from  './strive/NotificationManagement/EditTemplate.jsx';
import EventMapping           from  './strive/NotificationManagement/EventMapping.js';
import NotificationVariable   from  './strive/NotificationManagement/NotificationVariableList.js';
import EventToken             from  './strive/NotificationManagement/EventToken.js';

import OrderList              from './strive/orders/orderList.js';
import OrderDetails           from './strive/orders/orderDetails';   
import MyPreference             from  './strive/preference/MyPreference.js'

class Layout extends Component  {

   constructor(props) {
      super();
      this.state = {
         loggedIn: false,
      }
   }

   componentDidMount() {
      const userDetails = localStorage.getItem("userDetails");
      if(userDetails){
         var userData = JSON.parse(userDetails);
         if(userData.token !== null && userData.token !== "undefined") {
            this.setState({
               loggedIn: true
            })
         }         
      }      
   }

   logout() {
      const userDetails = localStorage.getItem("userDetails");
      if(userDetails){
         var userData = JSON.parse(userDetails);
         if(userData.token !== null && userData.token !== "undefined") {
            this.setState({
               loggedIn: false
            })
         }         
      }
   }

   render() {
      
      // console.log("this.state.loggedIn => ",this.state.loggedIn);

      if(this.state.loggedIn){
         return (
            <Router>
               <div className="hold-transition skin-blue fixed sidebar-mini">
                  <div className="wrapper">
                     <Header />
                     <div>
                        <Leftsidebar/>
                        <div className="main-page-wrapper" id="main-page-wrapper">
                           <Switch>
                              <Route path="/user-management" component={UMListOfUsers} exact />
                              <Route path="/create-user" component={CreateUser} exact />
                              <Route path="/DeletedUsers" component={DeletedUsers} exact />
                              <Route path="/umroleslist" component={UMRolesList}strict exact />
                              <Route path="/umroleslist/:fieldID" exact strict component={UMRolesList} />
                              <Route path="/umroleslist/oneField/:fieldID" exact strict component={UMRolesList} />
                              <Route path="/dashboard" component={Dashboard} exact />
                              <Route path="/edituserprofile/:id" component={EditUserProfile} exact />
                              <Route path="/profile/:id" component={UserProfile} exact />
                              <Route path="/ViewTemplates" component={ViewTemplates} exact />
                              <Route path="/CreateTemplate" component={CreateTemplate} exact />
                              <Route path="/edit-notification-template/:type/:template_id" component={EditTemplate} exact />
                              <Route path="/EventMapping" component={EventMapping} exact />
                              <Route path="/NotificationVariableList" component={NotificationVariable} exact />
                              <Route path="/ViewAllNotification" component={ViewAllNotification} exact />
                              <Route path="/EventToken" component={EventToken} exact />
                              <Route path="/order-list" component={OrderList} exact/>
                              <Route path="/order-details/:id" component={OrderDetails} exact/>
                              <Route path="/my-preferences" component={MyPreference} exact/>
                              <Route path="/reset-pwd/:user_ID" exact strict component={ResetPasswordInternal} />
                              <Route path="/reset-pwd-admin/:user_ID" exact strict component={ResetPassword} />
                              
                           </Switch>
                        </div>
                     </div>
                  </div>
                  <Footer /> 
               </div>
            </Router>
         );
      }else{
         return (
            <div>
               <Router >
                  <Switch >
                     <Route path="/" exact strict component={Login} />
                     <Route path="/login" exact strict component={Login} />
                     <Route path="/forgotpassword" exact strict component={ForgotPassword} />
                     <Route path="/confirm-otp/:userID" exact strict component={ConfirmOtp} />
                     <Route path="/reset-pwd/:user_ID" exact strict component={ResetPassword} />
                     <Route path="/user-management" component={UMListOfUsers} exact />
                  </Switch>
               </Router>
            </div>
         );
      }
   }
}

 
export default Layout;