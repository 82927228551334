import React            from 'react';
import ReactDOM         from 'react-dom';
import App              from './App';
import reportWebVitals  from './reportWebVitals';
import { Provider }     from 'react-redux';
import thunk            from 'redux-thunk';
import reducer          from './store/reducer';
import { createStore, applyMiddleware } 
                        from 'redux';
import './index.css';

const configureStore = () => {   
  return createStore( reducer, applyMiddleware(thunk)) ;
}
const store = configureStore();

ReactDOM.render( <Provider store={store}> <App /> </Provider>,  document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
