import React, {
   Component
} from 'react';
import {
   render
} from 'react-dom';
import $ from "jquery";
import {
   BrowserRouter,
   Route,
   Switch,
   Link
} from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.min.css';
import './Leftsidebar.css';
// import './dashboard.css';


export default class LeftSidebar extends Component {

   constructor(props) {
      super(props);
      this.state={
         
      };
      this.closeIcon='fa-angle-left';
      this.openIcon='fa-angle-down';
      this.activeMenu=this.activeMenu.bind(this)
   }

   componentDidMount() {
      console.log("current size = ",window.screen.width);

      if(window.screen.width < 992){         
         document.getElementById("sidebar-menu").style.display = 'none';
         document.getElementById("sidebar").style.width = '0px';
         document.getElementById("baricon").style.left = '30px';
         document.getElementById("navbar1").style.marginLeft = '0px';

         document.getElementById("main-page-wrapper").style.marginLeft = '0px';
         document.getElementById("main-page-wrapper").style.maxWidth = parseInt(window.screen.width) + 'px';
         document.getElementById("main-page-wrapper").style.width = parseInt(window.screen.width) + 'px';

         document.getElementById("main-footer").style.left = '0px';
         document.getElementById("main-footer").style.width = parseInt(window.screen.width) + 'px';
      }

      var userDetails=JSON.parse(localStorage.getItem("userDetails"));
      const Token=userDetails.token;      
      this.setState({
         user_ID: userDetails.user_id,
         email: userDetails.email,
         profileImage: userDetails.image,
         firstname: userDetails.firstname,
         lastname: userDetails.lastname,
         fullname: userDetails.firstName+' '+userDetails.lastName,
         companyID: userDetails.companyID,
      }, () => {
         console.log("this.state.fullname=", this.state.fullname);
      });


      $("html,body").scrollTop(0);
      var getCurrentUrl=window.location.pathname;

      $(".sidebar-menu .singleTreeview a").filter(function(a, b) {
         if ($(this).attr('href') === getCurrentUrl) {
            $(b).addClass('active');
         }
      })
      $(".sidebar-menu .treeview li a").filter(function(a, b) {
         if ($(this).attr('href') === getCurrentUrl) {
            $(b).addClass('active');
            $($($(b).parent()).parent()).parent().addClass('menu-open');
            ($($(b).parent()).parent()).css("display", "block");
         }
      })      
   }

   componentWillUnmount() {
      $("script[src='/js/adminLte.js']").remove();
      $("link[href='/css/dashboard.css']").remove();
   }

   activeMenu(event) {
      event.preventDefault();
      var a=event.currentTarget
      var pathname=event.currentTarget.getAttribute("data-id");
      window.location=pathname
      $(".sidebar-menu .treeview-menu li a").removeClass("active-submenu");
      $(event.currentTarget).addClass("active-submenu");
   }

   eventclk1(event) {
      $(event.currentTarget).children(".menuContent").children(".rotate").toggleClass("down");
      var currentEvent=event.currentTarget
      var getCurrentUrl=window.location.pathname;
      localStorage.setItem("currentURL", getCurrentUrl)
      localStorage.setItem("currentEvent", currentEvent)
   }

   clickDashboard(event) {
      $('.treeview').not(event.currentTarget).removeClass('menu-open')
      $('.treeview-menu').css({
         'display': 'none'
      })
      $(event.currentTarget).addClass('active')
   }

   
   moveSidebar = (event)=>{
      var sidebarWidth = document.getElementById("sidebar").offsetWidth;
      console.log("sidebarWidth => ",sidebarWidth);
      console.log("window.screen.width => ",window.screen.width);

      if(window.screen.width > 992){
         if(sidebarWidth === 230){
            document.getElementById("sidebar").style.width = '42px';
            document.getElementById("baricon").style.left = '60px';
            document.getElementById("navbar1").style.marginLeft = '42px';

            document.getElementById("main-page-wrapper").style.marginLeft = '50px';
            document.getElementById("main-page-wrapper").style.maxWidth = (parseInt(window.screen.width) - 75) + 'px';
            document.getElementById("main-page-wrapper").style.width = (parseInt(window.screen.width) - 75) + 'px';

            document.getElementById("main-footer").style.left = '42px';
            document.getElementById("main-footer").style.width = (parseInt(window.screen.width) - 60) + 'px';
            
            document.getElementById("periLogo").src = '/images/periscopeLogo-small.png';

            var x = document.getElementsByClassName("sidebarMenuTitle");
            console.log("x.length => ",x.length);

            for(var i=0; i<x.length; i++) {
               console.log("i => ",i)
               console.log("x[i].classList => ",x[i].classList)
               x[i].classList.add("hideSidebarMenuTitle");
            }         
         }else{
            document.getElementById("sidebar").style.width = '230px';         
            document.getElementById("baricon").style.left = '250px';
            document.getElementById("main-footer").style.left = '230px';
            document.getElementById("navbar1").style.marginLeft = '230px';

            document.getElementById("main-page-wrapper").style.maxWidth = (parseInt(window.screen.width) - 260) + 'px';
            document.getElementById("main-page-wrapper").style.width = (parseInt(window.screen.width) - 290) + 'px';
            document.getElementById("main-page-wrapper").style.marginLeft = '250px';
            document.getElementById("main-footer").style.left = '230px';
            document.getElementById("main-footer").style.width = (parseInt(window.screen.width) - 230) + 'px';

            document.getElementById("periLogo").src = '/images/periscopeLogo-long.png';

            var x = document.getElementsByClassName("sidebarMenuTitle");
            for(var i=0; i<x.length; i++) {
               x[i].classList.remove("hideSidebarMenuTitle");
            }
         }
      }else{
         if(sidebarWidth === 230){
            document.getElementById("sidebar-menu").style.display = 'none';
            document.getElementById("sidebar").style.width = '0px';
            document.getElementById("baricon").style.left = '30px';
            document.getElementById("navbar1").style.marginLeft = '0px';

            document.getElementById("main-page-wrapper").style.marginLeft = '0px';
            document.getElementById("main-page-wrapper").style.maxWidth = parseInt(window.screen.width) + 'px';
            document.getElementById("main-page-wrapper").style.width = parseInt(window.screen.width) + 'px';

            document.getElementById("main-footer").style.left = '0px';
            document.getElementById("main-footer").style.width = parseInt(window.screen.width) + 'px';
         }else{
            document.getElementById("sidebar-menu").style.display = 'block';
            document.getElementById("sidebar").style.width = '230px';         
            document.getElementById("baricon").style.left = '250px';
            document.getElementById("navbar1").style.marginLeft = '230px';

            document.getElementById("main-page-wrapper").style.marginLeft = '250px';
            document.getElementById("main-page-wrapper").style.maxWidth = parseInt(window.screen.width) + 'px';
            document.getElementById("main-page-wrapper").style.width = parseInt(window.screen.width) + 'px';
            
            // var width = parseInt(window.screen.width) - 307 ;
            // width < 200 ? document.getElementById("main-page-wrapper").style.width = parseInt(window.screen.width) + 'px';

            document.getElementById("main-footer").style.left = '230px';
            document.getElementById("main-footer").style.width = parseInt(window.screen.width) + 'px';
         }

      }

   }


   render() {
      return ( 
         <aside className="main-sidebar" id="sidebar">
            <section className="">
               <div className="col-12 px-0 logoWrapper1" id="periLogoWrapper">
                  <img className="periLogo" id="periLogo" src="/images/periscopeLogo-long.png" />
               </div>

               <span id="baricon" className="side-menu-baricon" onClick={this.moveSidebar}> 
                  <i className="fa fa-bars bg-white pull-right" aria-hidden="true"> </i> 
               </span>

               <ul className="sidebar-menu" id="sidebar-menu" data-widget="tree">
                  <a href="/order-list" title="Order List" onClick={() => this.openMenu("dashboard")}>
                     <li className="singleTreeview" onClick={this.clickDashboard.bind(this)}>
                        <i className="fa fa-ticket" aria-hidden="true"> </i>
                        <span className="d sidebarMenuTitle"> &nbsp; Order List </span> 
                     </li>
                  </a> 

                  <a href={"/profile/"+this.state.user_ID} id="sidebar-menu" title="My Profile" onClick={() => this.openMenu("dashboard")}>
                     <li className="singleTreeview" onClick={ this.clickDashboard.bind(this) }>
                        <i className="fa fa-money" aria-hidden="true"> </i> 
                        <span className="d sidebarMenuTitle"> &nbsp; My Profile </span> 
                     </li>
                  </a> 

                  <a href={"/reset-pwd/"+this.state.user_ID} id="sidebar-menu" title="Reset Password" onClick={ () => this.openMenu("dashboard") }>
                     <li className="singleTreeview" onClick={ this.clickDashboard.bind(this)}>
                        <i className="fa fa-ticket" aria-hidden="true"> </i> 
                        <span className="d sidebarMenuTitle"> &nbsp; Reset Password </span> 
                     </li>
                  </a> 

                  <a href="/my-preferences" title="My Preferences" id="sidebar-menu" onClick={ () => this.openMenu("dashboard") }>
                     <li className="singleTreeview" onClick={ this.clickDashboard.bind(this) }>
                        <i className="fa fa-database" aria-hidden="true"> </i> 
                        <span className="d sidebarMenuTitle"> &nbsp; My Preferences </span> 
                     </li>
                  </a> 

               </ul> 
            </section> 
         </aside>
         );
   }
}