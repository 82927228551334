
import PhoneInput from 'react-phone-input-2';
import React, { Component } from 'react';
import $                        from 'jquery';
import jQuery                   from 'jquery';
import axios from 'axios';
import swal from 'sweetalert';
import 'font-awesome/css/font-awesome.min.css';
import 'bootstrap/js/modal.js';
import './userManagement.css';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng
} from "react-places-autocomplete";

const formValid = formerrors => {
  let valid = true;
  Object.values(formerrors).forEach(val => {
    val.length > 0 && (valid = false);
  })
  return valid;
}

const nameRegex = RegExp(/^[A-za-z']+( [A-Za-z']+)*$/);
const mobileRegex = RegExp(/^[0-9][0-9]{9}$/);
const emailRegex = RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);

class CreateUser extends Component {


   constructor(props) {
      super(props);
      this.state = {
         show: true,
         office: null,
         allPosts: null,
         gmapsLoaded: false,
         firstname: "",
         lastname: "",
         signupEmail: "",
         mobile: "",
         role: "-- Select --",
         formValues: "",
         adminRolesListData: [],

         formerrors: {
           firstname: "",
           companyID: "",
           lastname: "",
           signupEmail: "",
           mobile: "",
           role: "",
         },
         'buttonType': 'Register User'
      };
      this.handleChange = this.handleChange.bind(this);
   }

   componentDidMount() {      
      this.validation();
      this.getRole();

      var userDetails = localStorage.getItem("userDetails");      
      var userDetailsParse = JSON.parse(userDetails);
      this.setState({userDetails : userDetailsParse});
   }


   handleChange(event) {
      const datatype = event.target.getAttribute('data-text');
      const target = event.target;
      const name   = target.name;
      const value  = target.value;

      let formerrors = this.state.formerrors;
    
      switch (datatype) {
         case 'firstname':
           formerrors.firstname = nameRegex.test(value) && value.length > 0 ? '' : "Please Enter Valid Name";
           break;

         case 'lastname':
           formerrors.lastname = nameRegex.test(value) && value.length > 0 ? '' : "Please Enter Valid Name";
           break;

         case 'mobile':
           formerrors.mobile = mobileRegex.test(value) && value.length > 0 ? '' : "Please enter a valid Contact Number";
           break;

         case 'signupEmail':
           formerrors.signupEmail = emailRegex.test(value) && value.length > 0 ? "" : "Please enter a valid Email ID";
           break;

         case 'role':
           formerrors.role = value !== "--select--" ? "" : "Please select role";
           break;

         default:
           break;
      }

      this.setState({
         formerrors,
         [name]: value
      });
   }

   validation() {
      $.validator.addMethod("regxA1", function (value, element, regexpr) {
         return regexpr.test(value);
      }, "Please enter valid first name");

      $.validator.addMethod("regxA2", function (value, element, regexpr) {
         return regexpr.test(value);
      }, "Please enter valid last name");

      $.validator.addMethod("regxEmail", function (value, element, regexpr) {
         return regexpr.test(value);
      }, "Please enter valid email ID");

      $.validator.addMethod("regxmobile", function (value, element, regexpr) {
         return regexpr.test(value);
      }, "Please enter valid mobile number");

      $.validator.addMethod("regxRole", function (value, element, arg) {
         return arg !== value;
      }, "Please select the role");

      jQuery.validator.setDefaults({
         debug: true,
         success: "valid"
      });

      $("#userInfo").validate({
         rules: {
            firstname: {
               required: true,
               regxA1: /^[A-Za-z][A-Za-z0-9\-\s]/,
            },
            lastname: {
               required: true,
               regxA2: /^[A-Za-z][A-Za-z0-9\-\s]/,
            },
            signupEmail: {
               required: true,
               regxEmail: /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/
            },
            mobile: {
               required: true,
               regxmobile: /^(([0-9]*)|(([0-9]*)\.([0-9]*)))$/,
            },

            role: {
               required: true,
               regxRole: "--Select--"
            },
         },
         errorPlacement: function (error, element) {
            if (element.attr("name") === "firstname") {
               error.insertAfter("#firstname");
            }
            if (element.attr("name") === "lastname") {
               error.insertAfter("#lastname");
            }
            if (element.attr("name") === "signupEmail") {
               error.insertAfter("#signupEmail");
            }
            if (element.attr("name") === "mobile") {
               error.insertAfter("#mobile");
            }
            if (element.attr("name") === "role") {
               error.insertAfter("#role");
            }
         }
      });
   }

   createUser(event) {
      event.preventDefault();
      if ($('#userInfo').valid()) {
         const formValues = {
            "firstname"   : this.state.firstname,
            "lastname"    : this.state.lastname,
            "email"       : this.state.signupEmail,
            "mobNumber"   : (this.state.mobile).replace("-", ""),
            "pwd"         : "StriveMedical@123",
            "role"        : this.state.role,
            "status"      : "active",
         }

         // console.log('userid Post formValues ==>>> ', formValues)

         if(this.state.firstname && this.state.lastname && this.state.signupEmail && this.state.mobile){
            axios.post('/api/auth/post/signup/user', formValues)
               .then((res) => {
                  console.log('userid Post response => ', res.data)
                  if(res.data.message === 'Email Id already exist.'){
                     swal({
                        title: "It seems this account is already created!",
                        text: res.data.message,
                     });
                     this.setState({
                        show: false,
                        'buttonType': 'Register User'
                     })
                  } else {
                     var sendData = {
                        "event": "Create New Account", //Event Name
                        "toUser_id": res.data.ID, //To user_id(ref:users)
                        "toUserRole":"admin",
                        "variables": {
                           'first_name' : this.state.firstname,
                           'last_name'  : this.state.lastname,
                           'admin_name' : this.state.userDetails.firstName +" "+this.state.userDetails.lastName,
                           'user_phone' : this.state.mobile,
                           'user_email' : this.state.signupEmail,
                           'user_role'  : this.state.role,
                           'Password'   : "StriveMedical@123",
                        }
                     }

                     axios.post('/api/masternotifications/post/sendNotification', sendData)
                        .then((res) => {
                          // console.log('sendDataToUser in result==>>>', res.data.type)
                        })
                        .catch((error) => { console.log('notification error: ', error) })
                     
                     if(res.data.message === "USER_CREATED"){
                        swal("User Account Created Successfully!", "Email Id: "+this.state.signupEmail+"\n Default Password: "+"StriveMedical@123");
                        this.props.history.push("/user-management");
                     }else{
                        swal(res.data.message,"","error");
                     }

                  }
            })
            .catch((error) => {
               console.log("error => ",error);
              this.setState({ show: false })
            });

         } else {
            swal({
               title: "Please enter mandatory fields",
               text: "Please enter mandatory fields",
            });
         }
      }
  }


   getRole() {
		axios.post('/api/roles/get/list')
		  .then((response) => {
            // console.log("response from role =>",response.data)
            this.setState({
               adminRolesListData: response.data
            })			
         }).catch(function (error) {
            console.log("error from getrole function =>",error)
         });
	}


   camelCase(str) {
      return str
         .toLowerCase()
         .split(' ')
         .map(word => word.charAt(0).toUpperCase() + word.slice(1))
         .join(' ');
   }


   render() {
      const { formerrors } = this.state;
      
      // console.log("formerrors => ",formerrors);

      return (
         <div className="col-12 formWrapper px-0">
            <div className="col-12 content row bg-info">
               <div className="pageContent col-12 shadow pb-5">
                  <div className="col-12 pt-3">
                     <h4 className="row">Add New User</h4>
                  </div>
                  <hr className="hr-head container-fluid row mb-5" />


                  <div className="col-12 col-md-8 offset-md-2">
                     <form id="userInfo">
                        <div className="valid_box">
                           <div className="form-margin col-lg-6 col-md-6 col-xs-12 col-sm-12  valid_box ">
                              <label>First Name <span className="requiredsign">*</span></label>
                              <input type="text" style={{ textTransform: 'capitalize' }}
                                 className="form-control UMname has-content"
                                 id="firstname" ref="firstname" name="firstname" data-text="firstname" placeholder="First Name" onChange={this.handleChange}
                                 value={this.state.firstname} />
                           </div>

                           <div className="form-margin col-lg-6 col-md-6 col-xs-12 col-sm-12  valid_box">
                              <label>Last Name <span className="requiredsign">*</span></label>
                              <input type="text" className="form-control UMname  has-content"
                                 id="lastname" ref="lastname" name="lastname" data-text="lastname" onChange={this.handleChange}
                                 value={this.state.lastname} placeholder="Last Name" />
                           </div> 
                        </div>

                        <div className="valid_box">
                           <div className="form-margin col-lg-6 col-md-6 col-xs-12 col-sm-12  valid_box">
                              <label >Email ID <span className="requiredsign">*</span></label>
                              <input type="text" className="formFloatingLabels form-control  newinputbox" ref="signupEmail" name="signupEmail" id="signupEmail" data-text="signupEmail" onChange={this.handleChange} value={this.state.signupEmail}
                                 placeholder="Email" />
                           </div>
                           <div className="form-margin col-lg-6 col-md-6 col-xs-12 col-sm-12  valid_box">
                              <label >Mobile Number <span className="requiredsign">*</span></label>
                              <input type="number"  required pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}" className="formFloatingLabels form-control  newinputbox" ref="mobile" name="mobile" id="mobile" data-text="mobile" onChange={this.handleChange} value={this.state.mobile}
                                 placeholder="Mobile Number" />
                           </div>
                        </div>

                        <div className="valid_box">
                           <div className="form-margin col-lg-6 col-md-6 col-xs-12 col-sm-12  valid_box " >
                              <label >Role <span className="requiredsign">*</span></label>
                              <div className="input-group col-lg-12 col-md-12 col-xs-12 col-sm-12" >

                              <select ref="role" name="role"
                                      value={this.state.role}  id="role" onChange={this.handleChange.bind(this)}
                                       className="form-control">
                                        <option hidden>--Select--</option>
                                         {
                                          this.state.adminRolesListData && this.state.adminRolesListData.length > 0 ?
                                          this.state.adminRolesListData.map((roledata, index)=>{
                                            return(      
                                                <option key={index} id={roledata.rolesentity} value={roledata.role}>{roledata.role}</option>
                                            );
                                          }
                                        ) : ''
                                      }
                              </select>
                            </div>
                          </div>
                        </div>

                        <div className="col-12 mt-5 mb-5">
                          <button className="col-12 col-md-4 offset-md-8 mt-5 btn resetBtn" onClick={this.createUser.bind(this)} id="CreateUserModal">{this.state.buttonType}</button>
                        </div>
                     </form>
                  </div>
               </div>
            </div>
         </div>
      );
   }
}
export default CreateUser;
